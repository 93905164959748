import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';
import { Heading2 } from '../../atoms/Headings/Headings';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';

const BiographyBlock = ({ biography, headshot, jobTitle, name }) => {
  const bioBlockCss = css`
    flex: 0 50%;
    padding: ${uc('20px')};

    > p {
      margin-top: 10px;
    }

    .headerContainer {
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .left {
      width: ${uc('85px')};
      min-width: ${uc('85px')};
      margin-right: ${uc('15px')};
    }

    .right {
      h2 {
        margin: 0;
      }

      p {
        margin-bottom: 0;
        font-weight: ${fontWeights.bold};
      }
    }

    @media (${breakpoints.tablet}) {
      flex: 0 100%;
      padding: ${uc('20px 0')};
    }

    @media (${breakpoints.mobile}) {
      .headerContainer {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  `;

  return (
    <div css={bioBlockCss}>
      <div className="headerContainer">
        {headshot && headshot.asset && (
          <div className="left">
            <Image
              src={sanityImage(headshot)
                .auto('format')
                .width(85)
                .url()}
              alt={`${name} headshot`}
              title={`${name} headshot`}
            />
          </div>
        )}
        <div className="right">
          {name && name.length > 0 && (
            <Heading2 fontSize={fontSizes.thirty} color={colors.primary}>
              {name}
            </Heading2>
          )}
          {jobTitle && jobTitle.length > 0 && (
            <Text fontSize={fontSizes.eighteen} color={colors.darkGray.two}>
              {jobTitle}
            </Text>
          )}
        </div>
      </div>
      {biography && biography.length > 0 && (
        <Text fontSize={fontSizes.sixteen} color={colors.lightGray.five}>
          {biography}
        </Text>
      )}
    </div>
  );
};

BiographyBlock.propTypes = {
  biography: PropTypes.string,
  headshot: PropTypes.shape({ asset: PropTypes.shape({}) }),
  jobTitle: PropTypes.string,
  name: PropTypes.string,
};

BiographyBlock.defaultProps = {
  biography: '',
  headshot: {},
  jobTitle: '',
  name: '',
};

export default BiographyBlock;

/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  breakpoints,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';

const Grid = ({
  backgroundColor,
  children,
  className,
  flexDirection,
  flexWrap,
  paddingBottom,
  paddingTop,
  sectionStyles,
}) => {
  let marginTop = 'initial';
  if (sectionStyles.marginTop && sectionStyles.marginTopPercentage) {
    marginTop = `${sectionStyles.marginTop}%`;
  } else if (sectionStyles.marginTop) {
    marginTop = uc(`${sectionStyles.marginTop}px`);
  }

  const gridCss = css`
    width: 100%;
    margin-top: ${marginTop};
    margin-bottom: ${sectionStyles.marginBottom
      ? uc(`${sectionStyles.marginBottom}px`)
      : 'initial'};
    padding-top: ${sectionStyles.paddingTop
      ? sectionSettings[sectionStyles.paddingTop]
      : sectionSettings[paddingTop]};
    padding-bottom: ${sectionStyles.paddingBottom
      ? sectionSettings[sectionStyles.paddingBottom]
      : sectionSettings[paddingBottom]};
    background-color: ${sectionStyles.backgroundColor
      ? sectionSettings[sectionStyles.backgroundColor]
      : sectionSettings[backgroundColor]};
    background-position-x: center;
    background-position-y: ${sectionStyles.backgroundPositionY
      ? uc(`${sectionStyles.backgroundPositionY}px`)
      : 0};

    @media (${breakpoints.desktop}) {
      margin-top: ${sectionStyles.marginTop ? uc('15px') : 0};
      margin-bottom: ${sectionStyles.marginBottom ? uc('15px') : 0};
      padding-top: ${uc('30px')};
      padding-bottom: ${sectionStyles.paddingBottom ? uc('15px') : 0};
    }
  `;

  const gridContentCss = css`
    position: relative;
    display: flex;
    flex-direction: ${flexDirection};
    flex-wrap: ${flexWrap};

    @media (${breakpoints.desktop}) {
      max-width: calc(100% - ${uc('40px')});
      margin-right: auto;
      margin-left: auto;
    }
  `;

  return (
    <section className={className} css={gridCss}>
      <div css={gridContentCss}>{children}</div>
    </section>
  );
};

Grid.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

Grid.defaultProps = {
  backgroundColor: sectionSettings.transparent,
  className: '',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  paddingBottom: sectionSettings.medium,
  paddingTop: sectionSettings.medium,
  sectionStyles: [],
};

export default Grid;

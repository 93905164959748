import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';

import { unitConverter as uc } from '../../../styles/base';
import { Heading2 } from '../../atoms/Headings/Headings';
import PageWrapper from '../PageWrapper/PageWrapper';
import Section from '../../molecules/Section/Section';
import Grid from '../../atoms/Grid/Grid';
import BiographyBlock from '../../molecules/BiographyBlock/BiographyBlock';

const BiographyBlockSection = ({
  backgroundImage,
  biographyBlocks,
  heading,
  sectionStyles,
}) => {
  const sectionCss = css`
    background-image: url(${backgroundImage});
  `;
  const heading2Css = css`
    margin: ${uc('0 auto 30px auto')};
    text-align: center;
  `;

  return (
    <Section sectionStyles={sectionStyles} css={sectionCss}>
      <PageWrapper>
        <Heading2 css={heading2Css}>{heading}</Heading2>
        <Grid flexWrap="wrap">
          {biographyBlocks &&
            biographyBlocks.map(bioBlock => (
              <BiographyBlock key={bioBlock._key} {...bioBlock} />
            ))}
        </Grid>
      </PageWrapper>
    </Section>
  );
};

BiographyBlockSection.propTypes = {
  backgroundImage: PropTypes.shape({}),
  biographyBlocks: PropTypes.arrayOf(PropTypes.shape({})),
  heading: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

BiographyBlockSection.defaultProps = {
  backgroundImage: {},
  biographyBlocks: [],
  heading: '',
  sectionStyles: {},
};

export default BiographyBlockSection;
